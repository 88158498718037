import Image from "../../../../content/posts/welcome/image";
import site from "../../../../content/posts/welcome/site.svg";
import design from "../../../../content/posts/welcome/design.svg";
import tech from "../../../../content/posts/welcome/tech.svg";
import gatsby from "../../../../content/posts/welcome/gatsbyjs.svg";
import * as React from 'react';
export default {
  Image,
  site,
  design,
  tech,
  gatsby,
  React
};