import React from "react";
import Svg, { SvgProps } from "./svg";

export const EmailAddress = React.memo((props: SvgProps) => (
    <Svg { ...props } viewBox="0 0 72.7 9.9">
        <path d="M3.1,8.9c-0.9,0-1.7-0.3-2.2-0.9S0,6.7,0,5.6c0-1,0.3-1.9,0.8-2.5S2,2.3,2.8,2.3c0.8,0,1.4,0.3,1.9,0.8s0.7,1.2,0.7,2.1v0.6
            H1c0,0.8,0.2,1.3,0.6,1.7s0.9,0.6,1.5,0.6c0.7,0,1.4-0.1,2.1-0.4v0.9c-0.3,0.1-0.7,0.3-1,0.3S3.5,8.9,3.1,8.9z M2.8,3.1
            c-0.5,0-0.9,0.2-1.2,0.5S1.1,4.4,1,5h3.4c0-0.6-0.1-1.1-0.4-1.4S3.3,3.1,2.8,3.1z"/>
        <path d="M10,2.3c0.3,0,0.5,0,0.8,0.1l-0.1,0.9c-0.3-0.1-0.5-0.1-0.7-0.1c-0.5,0-1,0.2-1.3,0.6S8.1,4.7,8.1,5.4v3.4h-1V2.4h0.8L8,3.6
            h0c0.2-0.4,0.5-0.7,0.9-1S9.6,2.3,10,2.3z"/>
        <path d="M16.4,8.8V4.7c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.7,0-1.2,0.2-1.5,0.5S13,4.6,13,5.4v3.4h-1V2.4h0.8l0.2,0.9h0
            c0.2-0.3,0.5-0.6,0.8-0.7s0.8-0.3,1.2-0.3c0.8,0,1.4,0.2,1.7,0.6s0.6,1,0.6,1.8v4.2H16.4z"/>
        <path d="M19.3,0.6c0-0.2,0.1-0.4,0.2-0.5S19.7,0,19.8,0c0.2,0,0.3,0.1,0.4,0.2s0.2,0.3,0.2,0.5S20.4,1,20.3,1.1S20,1.3,19.8,1.3
            c-0.2,0-0.3-0.1-0.4-0.2S19.3,0.9,19.3,0.6z M20.3,8.8h-1V2.4h1V8.8z"/>
        <path d="M25.1,8.9c-0.9,0-1.7-0.3-2.2-0.9S22,6.7,22,5.6c0-1,0.3-1.9,0.8-2.5s1.2-0.9,2.1-0.9c0.8,0,1.4,0.3,1.9,0.8
            s0.7,1.2,0.7,2.1v0.6H23c0,0.8,0.2,1.3,0.6,1.7s0.9,0.6,1.5,0.6c0.7,0,1.4-0.1,2.1-0.4v0.9c-0.3,0.1-0.7,0.3-1,0.3
            S25.5,8.9,25.1,8.9z M24.8,3.1c-0.5,0-0.9,0.2-1.2,0.5S23.1,4.4,23.1,5h3.4c0-0.6-0.1-1.1-0.4-1.4S25.3,3.1,24.8,3.1z"/>
        <path d="M38.2,4.5c0,0.6-0.1,1.1-0.3,1.5s-0.4,0.8-0.7,1.1s-0.7,0.4-1.1,0.4c-0.3,0-0.6-0.1-0.8-0.3s-0.4-0.5-0.4-0.8h0
            c-0.2,0.3-0.4,0.6-0.7,0.8s-0.6,0.3-1,0.3c-0.6,0-1-0.2-1.4-0.6S31.2,6,31.2,5.3c0-0.8,0.2-1.4,0.7-1.9s1.1-0.7,1.8-0.7
            c0.3,0,0.6,0,0.9,0.1s0.6,0.1,0.9,0.2l-0.1,2.8v0.1c0,0.7,0.3,1,0.8,1c0.4,0,0.6-0.2,0.9-0.6s0.3-1,0.3-1.6c0-0.7-0.1-1.3-0.4-1.9
            s-0.7-0.9-1.2-1.2S34.6,1,33.9,1c-0.9,0-1.6,0.2-2.3,0.5s-1.1,0.9-1.5,1.5s-0.5,1.4-0.5,2.3c0,1.2,0.3,2.1,0.9,2.7s1.5,1,2.7,1
            c0.8,0,1.7-0.2,2.6-0.5v0.8c-0.8,0.3-1.6,0.5-2.6,0.5c-1.4,0-2.5-0.4-3.3-1.2s-1.2-1.9-1.2-3.3c0-1,0.2-1.9,0.6-2.7s1-1.4,1.8-1.8
            s1.7-0.7,2.7-0.7c0.8,0,1.6,0.2,2.2,0.5s1.2,0.9,1.5,1.5S38.2,3.7,38.2,4.5z M32.1,5.3c0,1,0.4,1.5,1.1,1.5c0.8,0,1.2-0.6,1.3-1.8
            l0.1-1.5c-0.3-0.1-0.6-0.1-0.9-0.1c-0.5,0-0.9,0.2-1.2,0.5S32.1,4.7,32.1,5.3z"/>
        <path d="M44.3,8.8V4.7c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.7,0-1.2,0.2-1.5,0.5s-0.5,1-0.5,1.8v3.4h-1V2.4h0.8l0.2,0.9h0
            c0.2-0.3,0.5-0.6,0.8-0.7s0.8-0.3,1.2-0.3c0.8,0,1.4,0.2,1.7,0.6s0.6,1,0.6,1.8v4.2H44.3z"/>
        <path d="M48.8,5.5l-2.2-3.1h1.1l1.7,2.5l1.7-2.5h1.1l-2.2,3.1l2.3,3.3h-1.1l-1.8-2.6l-1.8,2.6h-1.1L48.8,5.5z"/>
        <path d="M58,8.8V4.7c0-0.5-0.1-0.9-0.4-1.2S57,3.1,56.5,3.1c-0.7,0-1.2,0.2-1.5,0.5s-0.5,1-0.5,1.8v3.4h-1V2.4h0.8l0.2,0.9h0
            c0.2-0.3,0.5-0.6,0.8-0.7s0.8-0.3,1.2-0.3c0.8,0,1.4,0.2,1.7,0.6s0.6,1,0.6,1.8v4.2H58z"/>
        <path d="M60.8,8.2c0-0.3,0.1-0.5,0.2-0.6s0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2s0.2,0.3,0.2,0.6c0,0.3-0.1,0.4-0.2,0.6
            S61.7,9,61.5,9c-0.2,0-0.4-0.1-0.5-0.2S60.8,8.5,60.8,8.2z"/>
        <path d="M64,0.6c0-0.2,0.1-0.4,0.2-0.5S64.4,0,64.6,0c0.2,0,0.3,0.1,0.4,0.2s0.2,0.3,0.2,0.5S65.1,1,65,1.1s-0.2,0.2-0.4,0.2
            c-0.2,0-0.3-0.1-0.4-0.2S64,0.9,64,0.6z M65.1,8.8h-1V2.4h1V8.8z"/>
        <path d="M72.7,5.6c0,1-0.3,1.9-0.8,2.5s-1.3,0.9-2.2,0.9c-0.6,0-1.1-0.1-1.5-0.4s-0.8-0.7-1-1.2s-0.4-1.1-0.4-1.8
            c0-1,0.3-1.9,0.8-2.4s1.3-0.9,2.2-0.9c0.9,0,1.6,0.3,2.1,0.9S72.7,4.6,72.7,5.6z M67.8,5.6c0,0.8,0.2,1.4,0.5,1.9s0.8,0.6,1.4,0.6
            s1.1-0.2,1.5-0.6s0.5-1.1,0.5-1.9c0-0.8-0.2-1.4-0.5-1.9s-0.8-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.4,0.6S67.8,4.8,67.8,5.6z"/>
    </Svg>
));